<template>
<div>
    <div class="dropdown">
    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="packageGroupListActions" ref="packageGroupListActions" data-bs-toggle="dropdown" aria-expanded="false">
        İşlemler
    </button>
    <ul class="dropdown-menu" aria-labelledby="packageGroupListActions">
        
        <li v-if="item.status=='PE'"><a @click.prevent="edit(item)" data-bs-toggle="modal" data-bs-target="#kt_modal_2" class="dropdown-item">Düzenle</a></li>
        <li v-if="item.status=='PE'"><a @click.prevent="approve(item)" class="dropdown-item">Onayla</a></li>
        <li v-if="item.status=='PE'"><a @click.prevent="remove(item)" class="dropdown-item">Sil</a></li>
        <li v-if="item.status=='PE'">
            <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#kt_modal_1" @click.prevent="show(item)">İncele</a></li>
        
        </ul>
    </div>

      
    

</div>
</template>

<script>

export default {
    name:'ContractVerisonActions',
    props:['item'],

    methods:{
        remove(item){
            this.$emit('remove',item);
        },
        edit(item){
            this.$emit('edit',item);
        },
        approve(item){
            this.$emit('approve',item);
        },
        show(item){
            this.$emit('show',item);
        }
    },
    
   
}
</script>