<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Sözleşme Versiyonları ({{contractName}})</h3>

        
      </div>      
    </div>
    
    
    <div id="kt_account_profile_details" class="collapse show" v-if="items">


        

        <div class="card-body border-top p-9" v-show="!showDetail">
            
            <div>
                <a href="" data-bs-toggle="modal" data-bs-target="#kt_modal_2" @click.prevent="addNewVersion()" class="btn btn-primary float-right">Yeni Versiyon Ekle</a>
              <table class="table table-row-dashed align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Versiyon No</th>
                    <th>Sözleşme Adı</th>
                    <th>Durum</th>                    
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                    <td>{{item.version}}</td>
                    <td>{{item.contractName}}</td>
                    
                    
                    <td><span :class="'badge badge-light-'+statusses.class[item.status]">{{statusses.label[item.status]}}</span></td>                    
                    <td>
                        <ContractVerisonAction 
                            @edit="editVersion($event)" 
                            @remove="removeVersion($event)" 
                            @approve="approveVersion($event)" 
                            @show="showVersion($event)" :item="item" 
                        />

                    </td>
                    </tr>            
                </tbody>
              </table>
      
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" id="kt_modal_2">
  <div class="modal-dialog modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{mode=='new' ? 'Yeni Versiyon Ekle' : 'Versiyon Düzenleme'}}</h5>

        <!--begin::Close-->
        <div
          class="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="svg-icon svg-icon-2x"></span>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body">
        <editor
      v-model="newContractVersion.text"

      api-key = "zmiiy7clfyunnem5ib8d8egxn3eguo9xli1nmvk1m2ypjv44"
      :init="{
        height: 500,
        menubar: false,
        
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table',
        ],
        toolbar:
          'undo redo | formatselect | bold italic |  alignleft aligncenter alignright |  bullist numlist outdent indent | help',
      }"
    >
    </editor>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal">
          Kapat
        </button>

        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="saveVersion()"
          >
          Kaydet
        </button>
        
      </div>
    </div>
  </div>
</div>
    
<div class="modal fade" tabindex="-1" id="kt_modal_1">
  <div class="modal-dialog modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Versiyon İçeriği</h5>

        <!--begin::Close-->
        <div
          class="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="svg-icon svg-icon-2x"></span>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body">
        <p v-html="selectedVersion.text"></p>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal">
          Kapat
        </button>
        
      </div>
    </div>
  </div>
</div>
    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ContractVerisonAction from '@/components/dropdown/ContractVersionActions.vue';
import Swal from "sweetalert2/dist/sweetalert2.js";
import Editor from "@tinymce/tinymce-vue";



const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

let contractId = params.contractId;


var newVersionValues={
    text:"",
    contractId:parseInt(contractId),
    status:'PE'
}

export default  {
  name:'PackageGroups',
  props:['contractName'],
  components:{
      Editor,
      ContractVerisonAction
  },
  data(){
    
    return{        
        newContractVersion:newVersionValues,
        mode:'new',
        selectedVersion:{
            text:''
        },

      showDetail:false,

      
      statusses : {
        class:{
          'AC':'success',
          'DA':'danger',
          'PE':'warning'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif',
          'PE':'Beklemede'
        }
      },       
      items:null,
      countries:null,
      contractTypes:null,
      

    }
  },

  

  methods:{

      addNewVersion(){
          this.newContractVersion = newVersionValues;
          this.mode = 'new';
      },
      saveVersion(){
          let method = this.mode == 'edit' ? '/User/UpdateUserContractVersion' : '/User/NewUserContractVersion';
          api.post(method,this.newContractVersion).then((res)=>{
              Swal.fire({
                  title:'Başarılı',
                  icon:'success',
                  text:'Bilgiler güncellendi'
              }).then(()=>{
                  location.reload(true);
              });
          });
      },

      approveVersion(item){
          Swal.fire({
              title:'Emin misiniz?',
              icon:'warning',
              text:'Sözleşme versiyonunu onaylamak istediğinize emin misiniz?',
              showCancelButton:true,
              cancelButtonText:'Hayır',
              confirmButtonText:'Evet'

          }).then((data)=>{
              if(data.isConfirmed){
                this.mode = 'edit';
                this.newContractVersion={
                    contractVersionId:item.contractVersionId,
                    status:'AC'
                };
                this.saveVersion();
              }
          })
      },

      removeVersion(item){
          Swal.fire({
              title:'Emin misiniz?',
              icon:'warning',
              text:'Versiyonu silmek istediğinze emin misiniz?',
              showCancelButton:true,
              cancelButtonText:'Hayır',
              confirmButtonText:'Evet'

          }).then((data)=>{
              if(data.isConfirmed){
                  api.get('/User/DeleteUserContractVersion/'+item.contractVersionId).then(()=>{
                    location.reload(true);
                  })
              }
          })
      },

      

      editVersion(item){
          this.mode='edit';
          api.get('/User/GetUserContractVersion/'+item.contractVersionId).then((res)=>{
               this.newContractVersion = res.data;              
          })
      },

      showVersion(item){
         
          api.get('/User/GetUserContractVersion/'+item.contractVersionId).then((res)=>{
               this.selectedVersion = res.data;
              
          })
      },

  },


  created(){
      newVersionValues.contractId = parseInt(this.$route.query.contractId);
     api.get('/User/ListUserContractVersions/JJ/'+this.$route.query.contractId).then((res)=>{
         this.items = res.data;
     });

     setCurrentPageBreadcrumbs("Sözleşme Versionları", ["Ayarlar","Sözleşmeler",this.contractName]);
  },

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>